import React from "react";
import {ListGuesser, FieldGuesser } from "@api-platform/admin";
import {ImageField} from "react-admin";

export default props => (
    <ListGuesser {...props} title="Bannières marketing">
        <FieldGuesser source="title" label="Titre"/>
        <ImageField source="image.imageFile" label="Image"/>
        <FieldGuesser source="content" label="Contenu"/>
        <FieldGuesser source="buttonLabel" label="Libellé bouton"/>
        <FieldGuesser source="url" label="Lien bouton"/>
        <FieldGuesser source="inOfferList" label="Sur la page « Nos offres »"/>
    </ListGuesser>
);