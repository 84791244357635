import {createMuiTheme} from "@material-ui/core/styles";

export const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: "#FFCC33"
        },
        secondary: {
            main: "#000"
        }
    },
    overrides: {
        // targeting refresh button
        RaAppBar: {
            toolbar: {
                '& button': {
                    '&:not(:nth-child(1))': {
                        display: 'none'
                    }
                }
            }
        },
        RaFormInput: {
            input:{
                width: '50%'
            }
        }
    }
})
