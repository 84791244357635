import React from "react";
import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, RadioButtonGroupInput } from "react-admin";
import Title from "./Title";

export default props => (
    <CreateGuesser {...props} title={<Title/>}>
        <ReferenceInput
            source="page"
            reference="pages"
            label="Page"
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            emptyText="[Aucun]"
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <RadioButtonGroupInput source="column" choices={[
            {id: 1, name: '1'},
            {id: 2, name: '2'},
            {id: 3, name: '3'},
        ]}/>
        <InputGuesser source="position" label="Position"/>
    </CreateGuesser>
);
