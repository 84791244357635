import jwtDecode from "jwt-decode";

export default {
    login: ({ username, password }) => {
        const request = new Request(
            `${process.env.REACT_APP_CDN_URL}/api/login`,
            {
                method: "POST",
                body: JSON.stringify({ username, password }),
                headers: new Headers({ "Content-Type": "application/json" }),
            }
        );
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                localStorage.setItem("token", token);
            });
    },
    logout: () => {
        localStorage.removeItem("token");
        return Promise.resolve();
    },
    checkAuth: async () => {
        try {
            // Try to authenticate with certificate first to avoid login/password authentication
            if ('true' === process.env.REACT_APP_ENABLE_AUTH_CERTIFICATE && !localStorage.getItem("token")) {
                const request = new Request(
                    `${process.env.REACT_APP_CDN_URL}/api/jwt_token_from_certificate`,
                    {
                        method: "GET",
                    }
                );

                await fetch(request)
                    .then((response) => {
                        if (response.status < 200 || response.status >= 300) {
                            throw new Error(response.statusText);
                        }
                        return response.json();
                    })
                    .then(({token}) => {
                        localStorage.setItem("token", token);
                    });
            }

            if (
                !localStorage.getItem("token") ||
                new Date().getTime() / 1000 >
                jwtDecode(localStorage.getItem("token"))?.exp
            ) {
                return Promise.reject();
            }


            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem("token");
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
};
