import React from "react";
import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import Title from "./Title";
import { ReferenceInput, AutocompleteInput, ImageInput, ImageField } from "react-admin";
import EditorInput from "../Editor/EditorInput";


export default props => (
    <CreateGuesser {...props} title={<Title/>}>
        <InputGuesser source="title" label="Titre"/>
        <InputGuesser source="metaDescription" label="Méta-description" multiline/>
        <InputGuesser source="extract" label="Aperçu listing" multiline/>
        <EditorInput source="content" label="Contenu"/>
        <ImageInput source="thumbnail" label="Miniature" accept="image/*" helperText="Taille maximum autorisée : 500ko">
            <ImageField source="src" title="title"/>
        </ImageInput>
        <ImageInput source="image" label="Bannière d'en-tête" accept="image/*">
            <ImageField source="src" title="title"/>
        </ImageInput>
        <InputGuesser source="altImage" label="Description de l'image (texte alternatif)"/>
        <InputGuesser source="state" label="Publié"/>
        <ReferenceInput
            label="Bannière"
            source="banner"
            reference="marketing_banners"
            filterToQuery={searchText => ({ title: searchText })}
            allowEmpty
            emptyText="[Aucune]"
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
    </CreateGuesser>
);
