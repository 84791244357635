import {useNotify, Labeled} from "react-admin";
import {useField} from 'react-final-form';
import React from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';
import CKEditorCustom from '@ckeditor/ckeditor5-custom';
import '@ckeditor/ckeditor5-build-classic/build/translations/fr';
import FileUploadPlugin from "./FileUploadPlugin";
import StylePlugin from "./StylePlugin";
import '../../Style/ckeditor.css';
import {paletteColors} from './PaletteColors';
import sanitizeHtml from 'sanitize-html';
import 'rrg-font/RenaultIcon/style.css';

const EditorInput = ({source, record, label, target, options = {}}) => {
    const notify = useNotify();
    const field = useField(source);

    return (
        <Labeled label={label}>
            <CKEditor
                config={{
                    language: 'fr',
                    toolbar: {
                        items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'underline',
                            'fontColor',
                            'fontBackgroundColor',
                            'link',
                            'bulletedList',
                            'numberedList',
                            '|',
                            'indent',
                            'outdent',
                            'alignment',
                            '|',
                            'imageUpload',
                            'blockQuote',
                            'insertTable',
                            'htmlEmbed',
                            'mediaEmbed',
                            'imageResize',
                            'undo',
                            'redo',
                            'insertIcon',
                            'RhDropdown',
                            '|',
                            'RhSwiper',
                            'RhSwiperRemove',
                            'RhSwiperAdd',
                            'RhSwiperUp',
                            'RhSwiperDown',
                        ],
                        shouldNotGroupWhenFull: true
                    },
                    heading: {
                        options: [
                            { model: 'hero', view: {name: 'div', classes: 'heroHeadline'}, title: 'Hero Headline'},
                            { model: 'heading1', view: {name: 'div', classes: 'h1Headline'}, title: 'H1 Headline like'},
                            { model: 'heading2', view: {name: 'h2', classes: 'h2Headline'}, title: 'H2 Headline'},
                            { model: 'h2like', view: {name: 'div', classes: 'h2Headline'}, title: 'H2 Headline like'},
                            { model: 'heading3', view: {name: 'h3', classes: 'h3Headline'}, title: 'H3 Headline'},
                            { model: 'h3like', view: {name: 'div', classes: 'h3Headline'}, title: 'H3 Headline like'},
                            { model: 'heading4', view: {name: 'h4', classes: 'h4Headline'}, title: 'H4 Headline'},
                            { model: 'h4like', view: {name: 'div', classes: 'h4Headline'}, title: 'H4 Headline like'},
                            { model: 'heading5', view: {name: 'h5', classes: 'h5Headline'}, title: 'H5 Headline'},
                            { model: 'h5like', view: {name: 'div', classes: 'h5Headline'}, title: 'H5 Headline like'},
                            { model: 'heading6', view: {name: 'h6', classes: 'h6Headline'}, title: 'H6 Headline'},
                            { model: 'h6like', view: {name: 'div', classes: 'h6Headline'}, title: 'H6 Headline like'},
                            { model: 'subtitle1', view: {name: 'div', classes: 'subtitle1'}, title: 'Subtitle 1'},
                            { model: 'subtitle2', view: {name: 'div', classes: 'subtitle2'}, title: 'Subtitle 2'},
                            { model: 'paragraph1', view: {name: 'div', classes: 'body1'}, title: 'Body 1'},
                            { model: 'paragraph', view: {name: 'p', classes: 'body2'}, title: 'Body 2'},
                            { model: 'soustitre', view: {name: 'div', classes: 'caption'}, title: 'Caption'},
                        ]
                    },
                    fontColor: {
                        colors: paletteColors
                    },
                    fontBackgroundColor: {
                        colors: paletteColors
                    },
                    htmlEmbed: {
                        showPreviews: true,
                        sanitizeHtml: (inputHtml) => {
                            const outputHtml = sanitizeHtml(inputHtml, {
                                allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'iframe']),
                                allowedAttributes: false
                            });
                            return {
                                html: outputHtml,
                                hasChanged: true
                            };
                        }
                    },
                    image: {
                        styles: ['alignLeft', 'alignCenter', 'alignRight'],
                        resizeOptions: [
                            {
                                name: 'imageResize:original',
                                label: 'Original',
                                value: null
                            },
                            {
                                name: 'imageResize:50',
                                label: '50%',
                                value: '50'
                            },
                            {
                                name: 'imageResize:75',
                                label: '75%',
                                value: '75'
                            }
                        ],
                        toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageResize', '|', 'imageTextAlternative'],
                    },
                    link: {
                        decorators: {
                            openInNewTab: {
                                mode: 'manual',
                                label: 'Ouvrir dans un nouvel onglet',
                                attributes: {
                                    target: '_blank',
                                    rel: 'noopener noreferrer'
                                }
                            },
                            style1: {
                                mode: 'manual',
                                label: 'HyperTextLink 1',
                                attributes: {
                                    class: 'hypertextLink1',
                                }
                            },
                            style2: {
                                mode: 'manual',
                                label: 'HyperTextLink 2',
                                attributes: {
                                    class: 'hypertextLink2',
                                }
                            },
                            buttonPrimary: {
                                mode: 'manual',
                                label: 'Bouton principal',
                                attributes: {
                                    class: 'RhBtn RhBtnPrimary',
                                }
                            },
                            buttonSecondary: {
                                mode: 'manual',
                                label: 'Bouton secondaire',
                                attributes: {
                                    class: 'RhBtn RhBtnSecondary',
                                }
                            },
                            buttonTexte: {
                                mode: 'manual',
                                label: 'Bouton texte',
                                attributes: {
                                    class: 'RhBtn RhBtnText',
                                }
                            }
                        }
                    },
                    table: {
                        contentToolbar: [
                            'tableColumn',
                            'tableRow',
                            'mergeTableCells',
                            'tableProperties',
                            'tableCellProperties'
                        ]
                    },
                    fileUploadPlugin: {
                        notify,
                        adapter: {
                            apiUrl: `${process.env.REACT_APP_CDN_URL}`,
                            routeUpload: `${process.env.REACT_APP_API_URL}/uploads`,
                            maxSize: 1572864, // octets
                        }
                    },
                    extraPlugins: [StylePlugin, FileUploadPlugin]
                }}

                editor={CKEditorCustom}
                data={record.content}
                onChange={(event, editor) => {
                    field.input.onChange(editor.getData());
                }}
                // onReady={ editor => {
                //     CKEditorInspector.attach( editor );
                // } }
            />
        </Labeled>
    );
}

export default EditorInput;
