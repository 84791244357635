import React from "react";
import {ListGuesser, FieldGuesser } from "@api-platform/admin";

export default props => (
  <ListGuesser {...props} title="Utilisateurs">
    <FieldGuesser source="email" label="Email"/>
    <FieldGuesser source="firstName" label="Prénom"/>
    <FieldGuesser source="lastName" label="Nom" />
    <FieldGuesser source="certificateUid" label="Identifiant certificat" />
  </ListGuesser>
);
