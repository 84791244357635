import React from "react";
import {ShowGuesser} from "@api-platform/admin";
import {TextField, ImageField, BooleanField} from "react-admin";
import Title from "./Title";

export default props => (
    <ShowGuesser {...props} title={<Title/>}>
        <TextField source="title" label="Titre"/>
        <ImageField source="image.imageFile" label="Image"/>
        <TextField source="altImage" label="Description de l'image (texte alternatif)"/>
        <TextField source="content" label="Contenu"/>
        <TextField source="buttonLabel" label="Libellé du bouton"/>
        <TextField source="url" label="Lien interne du bouton"/>
        <BooleanField source="inOfferList" label="Sur la page « Nos offres »"/>
    </ShowGuesser>
);
