import React from "react";
import {ShowGuesser} from "@api-platform/admin";
import { ReferenceField, TextField } from "react-admin";
import Title from "./Title";

export default props => (
    <ShowGuesser {...props} title={<Title/>}>
        <ReferenceField label="Page" source="page['@id']" reference="pages">
            <TextField source="title" />
        </ReferenceField>
        <TextField source="column" label="Colonne"/>
        <TextField source="position" label="Position"/>
    </ShowGuesser>
);
