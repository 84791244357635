import React from "react";
import {ListGuesser, FieldGuesser } from "@api-platform/admin";
import {ImageField} from "react-admin";

export default props => (
    <ListGuesser {...props} title="Bannières marketing">
        <FieldGuesser source="title" label="Titre"/>
        <FieldGuesser source="state" label="Publié"/>
        <FieldGuesser source="slug" label="Slug"/>
        <ImageField source="image.imageFile" label="Image"/>
        <FieldGuesser source="createdAt" label="Date de création"/>
        <FieldGuesser source="updatedAt" label="Date de dernière modification"/>
    </ListGuesser>
);
