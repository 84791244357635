import React from "react";
import { Layout, AppBar, Title, UserMenu } from "react-admin";
import { Grid, Typography } from "@material-ui/core/";
import { RhLogo } from "../../Assets/RhLogo";
import {withDataProvider} from "ra-core";

// page d"accueil
const CustomDashboard = () => {
    return  (
        <Grid container direction={"column"} alignItems={"center"}>
            <Title title={"RRG Jobs Admin"}/>
            <Grid item style={{paddingBottom: 40, paddingTop: 40}}>
                <Typography variant={"h5"} align={"center"}>
                    Bienvenue sur la page d&apos;administration de Jobs France RenaultRetailGroup
                </Typography>
            </Grid>
            <Grid item>
                <RhLogo fill={"#000"}/>
            </Grid>
        </Grid>
    )
};

export default withDataProvider(CustomDashboard)

// override de l icone du menu user de la nav
const CustomUserMenuIcon = props => {
    return (
        <span {...props}>A</span>
    )
};

// override du menu user de la nav
const CustomUserMenu = props => (<UserMenu {...props} icon={<CustomUserMenuIcon />} />);

// override de la nav
const customAppBar = props => {
    return (
        <AppBar {...props} userMenu={<CustomUserMenu {...props}/>}>
            <h6 className="MuiTypography-root Component-title-8 MuiTypography-h6 MuiTypography-colorInherit" id="react-admin-title" style={{flex: 1}}/>
            {/*<RhLogo height={32}/>*/}
            <div style={{flex: 1}}/>
        </AppBar>
    )
};

export const CustomLayout = props => <Layout {...props} appBar={customAppBar} />;
