import React from "react";
import {EditGuesser, InputGuesser} from "@api-platform/admin";
import { ReferenceInput, AutocompleteInput, RadioButtonGroupInput } from "react-admin";
import Title from "./Title";

export default props => (
    <EditGuesser {...props} title={<Title/>} redirect={false}>
        <ReferenceInput
            source="page"
            reference="pages"
            label="Page"
            filterToQuery={searchText => ({ name: searchText })}
            allowEmpty
            format={v => (v && v['@id']) || v}
            emptyText="[Aucun]"
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
        <RadioButtonGroupInput source="column" choices={[
            {id: 1, name: '1'},
            {id: 2, name: '2'},
            {id: 3, name: '3'},
        ]}/>
        <InputGuesser source="position" label="Position"/>
    </EditGuesser>
);
