export default function FileUploadPlugin(editor) {
    const pluginConfig = editor.config.get('fileUploadPlugin');
    const notify = pluginConfig.notify;
    const fileRepository = editor.plugins.get('FileRepository');

    fileRepository.createUploadAdapter = (loader) => {
        return new FileUploadAdapter(loader, pluginConfig.adapter, notify);
    };

    // Upload des fichiers via drag&drop
    editor.editing.view.document.on('drop', (evt, data) => {
        const fileToUpload = data.dataTransfer.files[0];

        // Pas de fichier droppé
        if (undefined === fileToUpload) {
            return;
        }

        // Le lancement de l'upload des images par drag&drop est géré nativement par ckeditor
        if (null !== fileToUpload.type.match(/^image\/.+/)) {
            return;
        }

        const loader = fileRepository.createLoader(fileToUpload);

        loader.read().then(() => {
            return loader.upload();
        }).then(data => {
            fileRepository.destroyLoader(loader);

            // Insertion du lien du fichier
            editor.model.change(writer => {
                const insertPosition = editor.model.document.selection.getFirstPosition();
                writer.insertText(data.default, {linkHref: data.default}, insertPosition);
            });
        })
    });
}

class FileUploadAdapter {
    constructor(loader, config, notify) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        // setup AbortController
        this.controller = new AbortController();

        this.apiUrl = config.apiUrl;
        this.routeUpload = config.routeUpload;
        this.maxSize = config.maxSize;
        this.notify = notify;
    }

    // Starts the upload process.
    upload() {
        const self = this;
        return this.loader.file.then(file => new Promise((resolve, reject) => {

            if (this.maxSize < file.size) {
                this.notify(`Fichier trop volumineux, la taille ne doit pas dépasser ${Math.floor(this.maxSize / 1024)} Ko`, 'warning');
                reject();
                return;
            }

            const body = new FormData();

            body.append('file', file);

            // signal to pass to fetch
            const signal = this.controller.signal;

            signal.addEventListener("abort", () => {
                console.log("upload annulé!")
            })

            this.notify('Upload en cours...')

            return fetch(
                this.routeUpload,
                {
                    body,
                    method: 'POST',
                    signal: signal,
                }
            ).then(response => {
                response.json().then(data => {
                    this.notify('Fichier uploadé')
                    resolve({
                        default: `${this.apiUrl}/${data.path}`
                    });
                });
            }).catch((error) => {
                console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
                this.notify('Le fichier n\'a pu être uploadé', 'warning');
            });

        }))
    }

    // Aborts the upload process.
    abort() {
        // Reject the promise returned from the upload() method.
        this.controller.abort();
        this.loader.abort();
        this.notify('Upload interrompu', 'warning')
    }
}
