import React from "react";
import {ShowGuesser} from "@api-platform/admin";
import {TextField, DateField, BooleanField, RichTextField, ReferenceField, ImageField} from "react-admin";
import Title from "./Title";
import '../../Style/ckeditor.css';

export default props => (
    <ShowGuesser {...props} title={<Title/>}>
        <TextField source="slug" label="Slug"/>
        <TextField source="title" label="Titre"/>
        <TextField source="metaDescription" label="Méta-description"/>
        <TextField source="extract" label="Aperçu listing" class={"ck-content"}/>
        <RichTextField source="content" label="Contenu" class={"ck-content"}/>
        <ImageField source="thumbnail.imageFile" label="Miniature"/>
        <ImageField source="image.imageFile" label="Bannière d'en-tête"/>
        <TextField source="altImage" label="Description de l'image (texte alternatif)"/>
        <BooleanField source="state" label="Publié"/>
        <DateField source="createdAt" label="Date de création"/>
        <DateField source="updatedAt" label="Date de dernière modification"/>
        <ReferenceField label="Bannière" source="banner['@id']" reference="marketing_banners">
            <TextField source="title" />
        </ReferenceField>
    </ShowGuesser>
);
