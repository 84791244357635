import React from "react";
import {email} from 'react-admin';
import {EditGuesser, InputGuesser} from "@api-platform/admin";
import Title from "./Title";

const validateEmail = email();

export default props => (
  <EditGuesser {...props} title={<Title/>}>
    <InputGuesser source="email" label="Email" validate={validateEmail}/>
    <InputGuesser source="firstName" label="Prénom"/>
    <InputGuesser source="lastName" label="Nom" />
    <InputGuesser source="plainPassword" label="Mot de passe" />
    <InputGuesser source="certificateUid" label="Identifiant certificat" />
  </EditGuesser>
);
