import React from "react";
import {EditGuesser, InputGuesser} from "@api-platform/admin";
import Title from "./Title";
import { ReferenceInput, AutocompleteInput, ImageInput, ImageField } from "react-admin";
import EditorInput from "../Editor/EditorInput";

export default props => (
    <EditGuesser {...props} title={<Title/>} redirect={false}>
        <InputGuesser source="slug" label="Slug"/>
        <InputGuesser source="title" label="Titre"/>
        <InputGuesser source="metaDescription" label="Méta-description" multiline/>
        <InputGuesser resettable options={{ multiline: true, rows: 3, rowsMax: 3 }} source="extract" label="Aperçu listing"/>
        <EditorInput source="content" label="Contenu"/>
        <ImageInput source="thumbnail" label="Miniature" accept="image/*" helperText="Taille maximum autorisée : 500ko">
            <ImageField source="imageFile" title="title"/>
        </ImageInput>
        <ImageInput source="image" label="Bannière d'en-tête" accept="image/*">
            <ImageField source="imageFile" title="title"/>
        </ImageInput>
        <InputGuesser source="altImage" label="Description de l'image (texte alternatif)"/>
        <InputGuesser source="state" label="Publié"/>
        <ReferenceInput
            label="Bannière"
            source="banner"
            reference="marketing_banners"
            filterToQuery={searchText => ({ title: searchText })}
            allowEmpty
            format={v => (v && v['@id']) || v}
            emptyText="[Aucune]"
        >
            <AutocompleteInput optionText="title" />
        </ReferenceInput>
    </EditGuesser>
);
