import React from "react";
import {
    HydraAdmin,
    hydraDataProvider,
    ResourceGuesser,
    fetchHydra as baseFetchHydra,
    useIntrospection
} from "@api-platform/admin";
import {Redirect} from "react-router-dom";
import {parseHydraDocumentation} from "@api-platform/api-doc-parser";
import polyglotI18nProvider from 'ra-i18n-polyglot';
import frenchMessages from 'ra-language-french';
import {UserList, UserCreate, UserShow, UserEdit} from './Components/User'
import {NewsList, NewsCreate, NewsShow, NewsEdit} from './Components/News'
import {MarketingBannerList, MarketingBannerCreate, MarketingBannerShow, MarketingBannerEdit} from './Components/MarketingBanner'
import {PageList, PageCreate, PageEdit, PageShow} from './Components/Page';
import {customTheme} from "./Theming/theme";
import CustomDashboard, {CustomLayout} from "./Components/Layout/customLayout";
import {CustomLoginPage} from "./Components/Login/CustomLoginPage";
import authProvider from "./authProvider";
import {FooterLinkList, FooterLinkCreate, FooterLinkEdit, FooterLinkShow} from './Components/FooterLink';
import {convertBlobToBase64} from "./Components/Helper/Utils";

const i18nProvider = polyglotI18nProvider(() => frenchMessages, 'fr');

const entrypoint = process.env.REACT_APP_API_URL;


const getHeaders = () => localStorage.getItem("token") ? {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
} : {};

const fetchHydra = (url, options = {}) =>
    baseFetchHydra(url, {
        ...options,
        headers: getHeaders,
    });

const RedirectToLogin = () => {
    const introspect = useIntrospection();

    if (localStorage.getItem("token")) {
        introspect();
        return <></>;
    }
    return <Redirect to="/login"/>;
};

const myApiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint)
.then( ({ api }) => {
    api.resources.map(resource => {
        resource.fields.map(field => {
            field.normalizeData = value => {
                if (value && value.rawFile instanceof File) {
                    return convertBlobToBase64(value.rawFile).then(result => {return {imageFile:`${value.title}%%%${result}`}} )
                }

                if (value && value['@id']) {
                    return value['@id']
                }

                return value;
            };

            return field;
        });

        return resource;
    });

    return { api };
});


const dataProvider = hydraDataProvider(
    entrypoint,
    fetchHydra,
    myApiDocumentationParser,
    true
);

export default () => (
    <HydraAdmin
        loginPage={CustomLoginPage}
        authProvider={ authProvider }
        entrypoint={entrypoint}
        i18nProvider={i18nProvider}
        dataProvider={dataProvider}
        theme={customTheme}
        layout={CustomLayout}
        dashboard={CustomDashboard}
    >
        <ResourceGuesser
            name="users"
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            create={UserCreate}
            options={{label: 'Utilisateurs'}}
            title="Utilisateurs"
        />

        {/* Demande liée à l'envoi par mail des messages de contact, à réactiver ou déplacer plus tard */}
        {/*<ResourceGuesser*/}
        {/*    name="contact_messages"*/}
        {/*    list={ContactMessageList}*/}
        {/*    show={ContactMessageShow}*/}
        {/*    options={{label: 'Message de contact'}}*/}
        {/*    title="Message de contact"*/}
        {/*/>*/}

        <ResourceGuesser
            name="pages"
            list={PageList}
            show={PageShow}
            edit={PageEdit}
            create={PageCreate}
            options={{label: 'Page'}}
            title="Page"
        />

        <ResourceGuesser
            name="news"
            list={NewsList}
            show={NewsShow}
            edit={NewsEdit}
            create={NewsCreate}
            options={{label: 'Actualités'}}
            title="Actualités"
            // icon={DescriptionIcon}
        />

        <ResourceGuesser
            name="marketing_banners"
            list={MarketingBannerList}
            show={MarketingBannerShow}
            edit={MarketingBannerEdit}
            create={MarketingBannerCreate}
            options={{label: 'Bannière marketing'}}
            title="Bannière marketing"
            // icon={DescriptionIcon}
        />
        <ResourceGuesser
            name="footer_links"
            list={FooterLinkList}
            show={FooterLinkShow}
            edit={FooterLinkEdit}
            create={FooterLinkCreate}
            options={{label: 'Footer'}}
            title="Footer"
        />
    </HydraAdmin>
);
