import React from "react";
import {CreateGuesser, InputGuesser} from "@api-platform/admin";
import {ImageInput, ImageField} from "react-admin";
import Title from "./Title";

export default props => (
    <CreateGuesser {...props} title={<Title/>}>
        <InputGuesser source="title" label="Titre"/>
        <ImageInput source="image" label="Image" accept="image/*">
            <ImageField source="src" title="title"/>
        </ImageInput>
        <InputGuesser source="altImage" label="Description de l'image (texte alternatif)"/>
        <InputGuesser multiline source="content" label="Contenu"/>
        <InputGuesser source="buttonLabel" label="Libellé du bouton"/>
        <InputGuesser source="url" label="Lien interne du bouton"/>
        <InputGuesser source="inOfferList" label="Afficher sur la page « Nos offres »"/>
    </CreateGuesser>
);
