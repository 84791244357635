import React from "react";
import {ListGuesser, FieldGuesser} from "@api-platform/admin";
import {ReferenceField, TextField} from "react-admin";

export default props => (
    <ListGuesser {...props} title="Page">
        <FieldGuesser source="title" label="Titre"/>
        <FieldGuesser source="slug" label="Slug"/>
        <FieldGuesser source="state" label="Active"/>
        <FieldGuesser source="inHeader" label="Affichée dans le menu"/>
        <ReferenceField label="Page parente" source="parent['@id']" reference="pages">
            <TextField source="title"/>
        </ReferenceField>
        <FieldGuesser source="position" label="Position"/>
        <FieldGuesser source="createdAt" label="Date de création"/>
        <FieldGuesser source="updatedAt" label="Date de dernière modification"/>
    </ListGuesser>
);
