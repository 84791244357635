import React from "react";
import { customTheme } from "../../Theming/theme";
import { ThemeProvider } from "@material-ui/styles";
import { Grid, TextField, Button, Card } from "@material-ui/core";
import { Notification, useLogin, useNotify } from "react-admin";
import bg from "../../Assets/rh_hero_homepage.webp";
import { RhLogo } from "../../Assets/RhLogo";

export const CustomLoginPage = ({ theme }) => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const login = useLogin();
    const notify = useNotify();
    const submit = e => {
        e.preventDefault();
        // will call authProvider.login({ username, password })
        login({ username, password }).catch(() =>
            notify("Identifiants incorrects")
        );
    };

    return (
        <ThemeProvider theme={customTheme}>
            <Grid
                container
                justify={"center"}
                alignItems={"center"}
                style={{
                    minHeight: "100vh",
                    backgroundImage: "url(" + bg + ")",
                    backgroundSize: "cover"
                }}
            >
                <Card style={{ width: 320, padding: 24 }}>
                    <form onSubmit={submit}>
                        <Grid
                            container
                            justify={"center"}
                            alignItems={"center"}
                            direction={"column"}
                        >
                            <RhLogo height={65} fill={"#000"} />
                            <TextField
                                id="username"
                                label="Identifiant"
                                value={username}
                                onChange={e => setUsername(e.target.value)}
                                type={"email"}
                                fullWidth
                                margin={"normal"}
                            />
                            <TextField
                                id="password"
                                label="Mot de passe"
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type={"password"}
                                fullWidth
                                margin={"normal"}
                            />
                            <Button
                                type={"submit"}
                                color={"primary"}
                                variant={"contained"}
                                style={{ marginTop: 40 }}
                            >
                                Se connecter
                            </Button>
                        </Grid>
                    </form>
                </Card>
                <Notification />
            </Grid>
        </ThemeProvider>
    );
};
