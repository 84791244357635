import React from "react";
import {ListGuesser, FieldGuesser } from "@api-platform/admin";
import { ReferenceField, TextField } from "react-admin";

export default props => (
    <ListGuesser {...props} title="Liens du footer">
        <ReferenceField label="Page" source="page['@id']" reference="pages">
            <TextField source="title" />
        </ReferenceField>
        <FieldGuesser source="column" label="Colonne"/>
        <FieldGuesser source="position" label="Position"/>
    </ListGuesser>
);
