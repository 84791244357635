import React from "react";
import {EditGuesser, InputGuesser} from "@api-platform/admin";
import {ReferenceInput, AutocompleteInput, ImageInput, ImageField} from "react-admin";
import Title from "./Title";
import EditorInput from "../Editor/EditorInput";

export default props => (
    <EditGuesser {...props} title={<Title/>} redirect={false}>
        <InputGuesser source="title" label="Titre"/>
        <InputGuesser source="slug" label="Slug"/>
        <ReferenceInput
            source="parent"
            reference="pages"
            label="Page parente"
            filterToQuery={searchText => ({title: searchText})}
            allowEmpty
            format={v => (v && v['@id']) || v}
            emptyText="[Aucune]"
        >
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <ReferenceInput
            label="Bannière marketing"
            source="banner"
            reference="marketing_banners"
            filterToQuery={searchText => ({title: searchText})}
            allowEmpty
            format={v => (v && v['@id']) || v}
            emptyText="[Aucune]"
        >
            <AutocompleteInput optionText="title"/>
        </ReferenceInput>
        <ImageInput source="image" label="En-tête de page" accept="image/*">
            <ImageField source="imageFile" title="title"/>
        </ImageInput>
        <InputGuesser source="altImage" label="Description de l'image (texte alternatif)"/>
        <InputGuesser source="position" label="Position"/>
        <InputGuesser source="state" label="Active"/>
        <InputGuesser source="inHeader" label="Afficher dans le menu"/>
        <InputGuesser source="metaDescription" label="Méta-description" multiline/>
        <EditorInput source="content" label="Contenu"/>
    </EditGuesser>
);
