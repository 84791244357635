export const paletteColors = [
    {
        color: '#FFFFFF',
        label: 'Blanc'
    },
    {
        color: '#000000',
        label: 'Noir'
    },
    {
        color: '#FAF9F8',
        label: 'Gris léger 1'
    },
    {
        color: '#F1F1F2',
        label: 'Gris léger 2'
    },
    {
        color: '#EEEEEE',
        label: 'Gris arrière plan'
    },
    {
        color: '#BBBCBC',
        label: 'Gris survol'
    },
    {
        color: '#999999',
        label: 'Gris principal'
    },
    {
        color: '#888B8D',
        label: 'Gris sombre 1'
    },
    {
        color: '#53565A',
        label: 'Gris sombre 2'
    },
    {
        color: '#333333',
        label: 'Gris sombre 3'
    },
    {
        color: '#222222',
        label: 'Gris sombre 4'
    },
    {
        color: '#FFCC33',
        label: 'Principal'
    },
    {
        color: '#FFF8E6',
        label: 'Principal léger'
    },
    {
        color: '#FFDB75',
        label: 'Principal survol'
    },
    {
        color: '#73C366',
        label: 'Vert'
    },
    {
        color: '#A7FB9A',
        label: 'Vert léger'
    },
    {
        color: '#88DE7F',
        label: 'Vert survol'
    },
    {
        color: '#4EA147',
        label: 'Vert sombre'
    },
    {
        color: '#FF8200',
        label: 'Orange'
    },
    {
        color: '#FFC14C',
        label: 'Orange léger'
    },
    {
        color: '#FFA51C',
        label: 'Orange survol'
    },
    {
        color: '#EB6500',
        label: 'Orange sombre'
    },
    {
        color: '#CB333B',
        label: 'Rouge'
    },
    {
        color: '#FF6E6A',
        label: 'Rouge léger'
    },
    {
        color: '#E85252',
        label: 'Rouge survol'
    },
    {
        color: '#8C0012',
        label: 'Rouge sombre'
    },
    {
        color: '#009B77',
        label: 'Vert 2'
    },
    {
        color: '#7CF7CF',
        label: 'Vert 2 léger'
    },
    {
        color: '#55D2AC',
        label: 'Vert 2 survol'
    },
    {
        color: '#006747',
        label: 'Vert 2 sombre'
    },
    {
        color: '#009CA6',
        label: 'Bleu 2'
    },
    {
        color: '#77EEF8',
        label: 'Bleu 2 léger'
    },
    {
        color: '#58D1DC',
        label: 'Bleu 2 survol'
    },
    {
        color: '#006772',
        label: 'Bleu 2 sombre'
    },
    {
        color: '#0077C8',
        label: 'Bleu 3'
    },
    {
        color: '#7AC5FF',
        label: 'Bleu 3 léger'
    },
    {
        color: '#5BAAFF',
        label: 'Bleu 3 survol'
    },
    {
        color: '#0048A0',
        label: 'Bleu 3 sombre'
    },
    {
        color: '#0033A0',
        label: 'Bleu 4'
    },
    {
        color: '#006ED6',
        label: 'Bleu 4 léger'
    },
    {
        color: '#1749C4',
        label: 'Bleu 4 survol'
    },
    {
        color: '#002280',
        label: 'Bleu 4 sombre'
    },
    {
        color: '#5C068C',
        label: 'Violet intense'
    },
    {
        color: '#AD5DDE',
        label: 'Violet intense léger'
    },
    {
        color: '#9143C2',
        label: 'Violet intense survol'
    },
    {
        color: '#430068',
        label: 'Violet intense sombre'
    },
    {
        color: '#9E007E',
        label: 'Violet'
    },
    {
        color: '#D750B3',
        label: 'Violet léger'
    },
    {
        color: '#BA3198',
        label: 'Violet survol'
    },
    {
        color: '#810065',
        label: 'Violet sombre'
    },
]
