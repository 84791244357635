import React from "react";
import {ShowGuesser} from "@api-platform/admin";
import {DateField, TextField, RichTextField, ReferenceField, BooleanField, ImageField} from "react-admin";
import Title from "./Title";
import '../../Style/ckeditor.css';

export default props => (
    <ShowGuesser {...props} title={<Title/>}>
        <TextField source="title" label="Titre"/>
        <TextField source="slug" label="Slug"/>
        <ImageField source="image.imageFile" label="En-tête de page"/>
        <TextField source="altImage" label="Description de l'image (texte alternatif)"/>
        <BooleanField source="state" label="Active"/>
        <BooleanField source="inHeader" label="Affichée dans le menu"/>
        <TextField source="metaDescription" label="Méta-description"/>
        <ReferenceField label="Parent" source="parent['@id']" reference="pages">
            <TextField source="title"/>
        </ReferenceField>
        <ReferenceField label="Bannière" source="banner['@id']" reference="marketing_banners">
            <TextField source="title"/>
        </ReferenceField>
        <TextField source="position" label="Position"/>
        <RichTextField source="content" label="Contenu" class={"ck-content"}/>
        <DateField source="createdAt" label="Date de création"/>
        <DateField source="updatedAt" label="Date de modification"/>
    </ShowGuesser>
);
