import React from "react";
import {ShowGuesser} from "@api-platform/admin";
import {TextField} from "react-admin";
import Title from "./Title";
import RichTextInput from 'ra-input-rich-text';

export default props => (
  <ShowGuesser {...props} title={<Title/>}>
    <TextField source="email" label="Email"/>
    <TextField source="firstName" label="Prénom"/>
    <TextField source="lastName" label="Nom" />
    <TextField source="certificateUid" label="Identifiant certificat" />
  </ShowGuesser>
);
